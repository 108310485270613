import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import Seo from "../components/seo"

const Iletisim = () => {
    return (
        <Layout>
            <Seo title="İletişim" />
            <div class="iletisim-cerceve">
                <StaticImage
                    src="../images/iletisim.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="iletisim"
                    className="üstimg"
                    placeholder={'blurred'}
                    objectPosition="top"
                />
                <h2 class="iletisim-slogan">Teklif ve Bilgi Almak İçin Bizimle İletişime Geçebilirsiniz...</h2>
                <div class="com5-1">
                    <div class="iletisim">
                        <h3>Bize Ulaşın</h3>
                        <div className="iletisimler">
                            <div className="iletisimkutu-1">
                                <i className="fas fa-map-marked-alt"></i>
                                <Link
                                    target="_blank"
                                    href="https://www.google.com/maps/place/%C5%9Eafak,+842.+Cd.+No:49,+06830+G%C3%B6lba%C5%9F%C4%B1%2FAnkara/@39.7968371,32.8109027,286m/data=!3m2!1e3!4b1!4m5!3m4!1s0x14d343d762d3d77d:0xa32c389ba2dbee15!8m2!3d39.796835!4d32.811997">
                                    Adres: Şafak Mah. 842.Sokak No:49 - Ankara
                                </Link>
                            </div>
                            <div className="iletisimkutu-1">
                                <i className="fas fa-phone-alt"></i>
                                <Link href="tel:+90-542-103-18-66">Telefon: 0(542) 103 18 66</Link>
                            </div>
                            <div className="iletisimkutu-1">
                                <i className="fas fa-envelope"></i>
                                <Link href="mailto:info@birucem.com">E-mail: info@birucem.com</Link>
                            </div>
                            <div className="iletisimkutu-1">
                                <i className="fas fa-clock"></i>
                                <Link href="./iletisim.html">Açık: Pzt - Cmt / 9:00 - 18:00</Link>
                            </div>
                        </div>

                    </div>
                    <div class="sosyallercerceve">
                        <h3>Bizi Takip Edin</h3>
                        <div class="sosyalkutu">
                            <div class="sosyaller-1">
                                <Link target="_blank" href="https://www.instagram.com/birucemmuhendislik"
                                    class="fab fa-instagram"></Link>
                            </div>
                            <div class="sosyaller-1">
                                <Link target="_blank" href="https://www.linkedin.com/in/bir%C3%BC%C3%A7em-m%C3%BChendislik-8114b9213/"
                                    class="fab fa-linkedin-in"></Link>
                            </div>
                            {/* <div class="sosyaller-1">
                                <Link target="_blank" href=""
                                    class="fab fa-facebook-f"></Link>
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>
            <div class="iletisim-harita">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3065.4527507521607!2d32.811997!3d39.796835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d343d762d3d77d%3A0xa32c389ba2dbee15!2zxZ5hZmFrLCA4NDIuIENkLiBObzo0OSwgMDY4MzAgR8O2bGJhxZ_EsS9BbmthcmE!5e0!3m2!1str!2str!4v1632318581772!5m2!1str!2str"
                    title="adres" width="100%" height="300" allowfullscreen="" loading="lazy"></iframe>
            </div>

        </Layout>
    )
}

export default Iletisim
